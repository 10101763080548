<template>
<section class="py-5">
        <div class="container-xxl">
            <div class="row">
                <div class="justify-content-center">
                    <p class="text1Answer display-5">Институты</p>
                </div>
                <div>
                    <button class="btn" v-if="$store.state.user.prevs.contacts" @click="addInstitute">Добавить институт</button>
                </div>
                <div v-for="(institute, iidx) in $store.state.contacts.institutes" :key="iidx">
                    <div class="text3Answer text-center">{{ institute.name }} ({{ institute.shortName }})</div>
                    <div><b>Директор:</b> {{ institute.director }}</div>
                    <div><b>Адрес:</b> <InteractiveAddress :auds="institute.auds" :full="true" @build-route="raiseBuildRoute"></InteractiveAddress></div>
                    <div>
                        <b>Телефон: </b>
                        <span v-for="(p, i) in institute.phones" :key="i"><a :href="'tel:8' + p" style="white-space: nowrap;">{{ formatPhoneNumber(p) }}</a>{{ institute.phones[i + 1] ? ', ' : '' }}</span>
                    </div>
                    <div>
                        <b>Эл. почта: </b>
                        <span v-for="(em, i) in institute.emails" :key="i"><a :href="'mailto:' + em" style="white-space: nowrap;">{{ em }}</a>{{ institute.emails[i + 1] ? ', ' : '' }}</span>
                    </div>
                    <div><button class="btn" v-if="$store.state.user.prevs.contacts" @click="addCathedra(institute.id)">Добавить кафедру</button></div>
                    <div v-if="cathedras[institute.id]">
                        <div class="mt-2">
                            <b>Кафедры:</b>
                        </div>
                        <div style="overflow-x: auto;">
                            <table class="table table-striped">
                                <thead>
                                    <th>Наименование</th>
                                    <th>Ауд.</th>
                                    <th>Заведующий</th>
                                    <th>Телефон</th>
                                    <th>E-Mail</th>
                                </thead>
                                <tbody>
                                    <tr v-for="(cat, cidx) in cathedras[institute.id]" :key="cidx">
                                        <td>{{ cat.name }}</td>
                                        <td><InteractiveAddress :auds="cat.auds" :full="false" @build-route="raiseBuildRoute"></InteractiveAddress></td>
                                        <td>{{ cat.director }}</td>
                                        <td>
                                            <span v-for="(p, i) in cat.phones" :key="i"><a :href="'tel:8' + p" style="white-space: nowrap;">{{ formatPhoneNumber(p) }}</a>{{ cat.phones[i + 1] ? ', ' : '' }}</span>
                                        </td>
                                        <td>
                                            <span v-for="(em, i) in cat.emails" :key="i"><a :href="'mailto:' + em" style="white-space: nowrap;">{{ em }}</a>{{ cat.emails[i + 1] ? ', ' : '' }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import store from '@/store';
import InteractiveAddress from './InteractiveAddress.vue';
import { contacts as loadContacts } from '../../dataLoader';
const api = require('../../api');
export default {
    components: {
        InteractiveAddress,
    },
    data() {
        return {};
    },
    computed: {
        cathedras() {
            let result = {};
            store.state.contacts.cathedras.forEach(c => {
                if (!result[c.institute]) result[c.institute] = [];
                result[c.institute].push(c);
            });
            return result;
        }
    },
    methods: {
        formatPhoneNumber(num) {
            num = String(num);
            if (num.startsWith('4') || num.startsWith('8') || num.startsWith('9')) return `8 (${num.slice(0, 3)}) ${num.slice(3, 3 + 3)}-${num.slice(6, 6 + 2)}-${num.slice(8, 8 + 2)}`;
            else return `8 (${num.slice(0, 4)}) ${num.slice(4, 4 + 2)}-${num.slice(6, 6 + 2)}-${num.slice(8, 8 + 2)}`;
        },
        async addInstitute() {
            let name = prompt('Полное наименование?');
            if (!name) return;
            
            let shortName = prompt('Краткое наименование (например, "ИСЭиУ")?');
            if (!shortName) return;
            
            let director = prompt('Директор?');
            if (!director) return;
            
            let auds = prompt('Аудитории?') || '';
            
            let phones = prompt('Номера телефонов (только цифры без "+7" и "8", например "3812001122" или "9501110011")?') || '';
            
            let emails = prompt('Электронная почта?') || '';

            if (!confirm(`Создаём институт "${name}" (${shortName})`
                + `\nДиректор: ${director}`
                + `\nАудитория: ${auds}`
                + `\nТелефон: ${phones}`
                + `\nПочта: ${emails}`)) return;
            try {
                await api.post('/contacts/institute', {
                    name,
                    shortName,
                    director,
                    auds: auds.split(', '),
                    phones: phones.split(', '),
                    emails: emails.split(', ')
                });
            }
            catch (e) {
                alert(e);
                console.error(e);
                return;
            }
            loadContacts();
        },
        async addCathedra(institute) {
            const instituteData = store.state.contacts.institutes.filter(x => x.id == institute)[0];
            let name = prompt('Наименование?');
            if (!name) return;
            
            let director = prompt('Заведующий?');
            if (!director) return;
            
            let auds = prompt('Аудитории?') || '';
            
            let phones = prompt('Номера телефонов (только цифры без "+7" и "8", например "3812001122" или "9501110011")?') || '';
            
            let emails = prompt('Электронная почта?') || '';

            if (!confirm(`Создаём кафедру "${name}" при институте "${instituteData.shortName}"`
                + `\nЗаведующий: ${director}`
                + `\nАудитория: ${auds}`
                + `\nТелефон: ${phones}`
                + `\nПочта: ${emails}`)) return;
            try {
                await api.post('/contacts/cathedra', {
                    name,
                    director,
                    auds: auds.split(', '),
                    phones: phones.split(', '),
                    emails: emails.split(', '),
                    institute
                });
            }
            catch (e) {
                alert(e);
                console.error(e);
                return;
            }
            loadContacts();
        },
        /**
         * Отправляет команду на построение маршрута выше по иерархии компонентов
         */
        raiseBuildRoute(...args) {
            this.$emit('buildRoute', ...args);
        }
    },
    store: store
}
</script>

<style scoped>
.text1Answer {
    font-weight: 500;
    text-align: center;
    color: #012E4B;
}
.text2Answer {
    font-weight: 400;
    color: #012E4B;
}
.text3Answer {
    font-size: 25px !important;
    text-align: justify;
    color: #012E4B;
}
</style>