<template>
    <div>
        <h4>Редактор точек</h4>
        <div v-if="locked">Заблокирован - активен редактор корридорных линий</div>
        <div v-else-if="editing">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">ID:</span>
                </div>
                <input class="form-control" v-model="id" :disabled="!isNew"/>
            </div>
            
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Имя для пользователя:</span>
                </div>
                <input class="form-control" v-model="name"/>
            </div>
            
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Группа точек:</span>
                </div>
                <input class="form-control" v-model="group"/>
            </div>
            
            <form>Тип точки:
                <label><input name="type" type="radio" v-model="isEndpoint" :value="true"/> Аудитория</label>
                <label><input name="type" type="radio" v-model="isEndpoint" :value="false"/> Промежуточная (лестница, переход и т.д.)</label>
            </form>
            
            <div>
                <div>Соединяется с {{ connectsTo.join('; ') || '(ничем)' }}</div>
                <div><label><input v-model="showAllConnects" type="checkbox"/> Показывать со всех этажей</label></div>
                <div v-if="group"><label><input v-model="showAllConnectsFromGroup" type="checkbox"/> Показывать из группы "{{ this.group }}"</label></div>
                <div v-for="i in showingConnects" :key="i">
                    <button class="btn link" @click="connectsTo.indexOf(i) == -1 ? addConnect(i) : rmConnect(i)">{{ i }}</button>
                </div>
            </div>
            
            <div>
                <span>Корридоная линия:</span>
                <select v-model="corridor">
                    <option value="">Щёлкните здесь для выбора</option>
                    <option v-for="id in availCorridorLines" :key="id" :value="id">{{ id }}</option>
                </select>
            </div>
            
            <div>
                Координаты:
                <span v-if="x != -1">({{ x }}; {{ y }})</span>
                <span v-else><b>НЕ ВЫБРАНЫ (щёлкните по карте для выбора)</b></span>
            </div>
            
            
            <div>
                <button class="btn" @click="submit">Подтвердить</button>
            </div>
            <div v-if="!isNew">
                <button class="btn" @click="remove">Удалить линию</button>
            </div>
            <div>
                <button class="btn" @click="quit">Отменить</button>
            </div>
        </div>
        
        <div v-else>
            <div><button class="btn" @click="addNew">Новая точка...</button></div>
            <div>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">ID существующей:</span>
                </div>
                <input class="form-control" v-model="editingId" @keydown.enter="editExisting"/>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
const { Point, CorridorLine } = require('../../mapCore');
export default {
    data() {
        return {
            floor: '1.1',
            locked: false,
            editing: false,
            isNew: false,
            editingId: '',
            showAllConnects: false,

            id: '',
            x: -1,
            y: -1,
            name: '',
            connectsTo: [],
            corridor: '',
            isEndpoint: true,
            group: '',

            availCorridorLines: [],
            availConnects: [],
            showAllConnectsFromGroup: true
        }
    },
    computed: {
        showingConnects() {
            return this.availConnects.filter(i => {
                if (this.showAllConnects) return true;
                if (i.startsWith(this.floor + '.')) return true;

                let p = Point.list[i];
                if (this.group && this.group == p.group && this.showAllConnectsFromGroup) return true;
                return false;
            });
        }
    },
    watch: {
        editing() {
            if (this.editing) {
                this.availCorridorLines = Object.keys(CorridorLine.list).filter(i => i.startsWith(this.floor + '.'));
                if (this.availCorridorLines.length == 1) this.corridor = this.availCorridorLines[0];
                this.availConnects = Object.keys(Point.list).filter(i => i != this.id && !Point.list[i].isEndpoint).sort();
            }
            else {
                this.$emit('ended-editing');
                this.isNew = false;
                this.id = '';
                this.x = -1;
                this.y = -1;
                this.name = '';
                this.connectsTo = [];
                this.availConnects = [];
                this.corridor = '';
                this.group = '';
            }
        },
        id() {
            if (!this.id) return;
            let spl = this.id.split('.');
            if (!isNaN(spl[2]) && spl[2] < 10) spl[2] = '0' + spl[2];
            this.name = `${spl[0]}.${spl.slice(1).join('')}`
        },
        floor() {
            this.quit();
        }
    },
    methods: {
        /**
         * Добавить соединение с другой точкой
         */
        addConnect(i) {
            this.connectsTo.push(i);
        },
        /**
         * Удалить соединение
         */
        rmConnect(i) {
            let idx = this.connectsTo.indexOf(i);
            if (i != -1) this.connectsTo.splice(idx, 1);
        },
        coordsSelected(x, y) {
            if (this.editing) {
                this.x = x;
                this.y = y;
                this.$emit('tempPoint', x, y);
            }
        },
        addNew() {
            this.$emit('startedEditing');
            this.editing = true;
            this.isNew = true;
        },
        submit() {
            if (!this.id) return alert('Вы не указали ID');
            if (this.isNew && Point.list[this.id]) return alert('Такая точка уже сществует');
            if (this.x == -1) return alert('Вы не выбрали координаты');
            if (!this.corridor) return alert('Вы не указали ID корридорной линии');

            this.$emit('submitPoint', this.id, this.name, this.connectsTo, { x: this.x, y: this.y }, this.corridor, this.isEndpoint, this.group);
        },
        editExisting(external) {
            if (typeof external == 'string') this.editingId = external;
            let p = Point.list[this.editingId];
            if (!p) return alert('Точка с таким ID не существует');
            this.$emit('startedEditing');
            this.isNew = false;
            this.id = p.id || '';
            this.x = p.coords.x;
            this.y = p.coords.y;
            this.connectsTo = p.connectsTo;
            this.corridor = p.corridor || '';
            this.isEndpoint = p.isEndpoint;
            this.editing = true;
            this.group = p.group || '';
        },
        quit() {
            this.editing = false;
        },
        remove() {
            this.$emit('removePoint', this.id);
        }
    }
}
</script>

<style>

</style>