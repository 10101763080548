<template>
    <div class="container-xxl">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-11 col-md-11 map border" id="leaflet">
                <MapDrawer ref="drawer"></MapDrawer>
                <div class="leaflet-top leaflet-right">
                    <div class="row">
                        <div class="d-flex flex-column align-items-center justify-content-center col" style="min-width: 55px">
                            <button class="btn floor-sel-btn" @click="showFloorSelectBtns = !showFloorSelectBtns">{{ showFloorSelectBtns ? '↑' : '↓' }}</button>
                        </div>
                    </div>

                    <div class="row" style="margin-right: 0;">
                        <div class="d-flex flex-column gap-1 p-2 align-items-center justify-content-center col" v-if="showFloorSelectBtns">
                            <div class="">Корпус</div>
                            <button class="btn floor-sel-btn" v-for="(floors, i) in floors" :key="i"
                                @click="setFacilityAndFloor(i, undefined)"
                                :class="selectedFacility == i ? null : 'not-selected'">{{ facilityDisplayNames[i] || i }}</button>
                        </div>

                        <div class="d-flex flex-column gap-1 p-2 align-items-center justify-content-center col" v-if="showFloorSelectBtns">
                            <div class="">Этаж</div>
                            <button class="btn floor-sel-btn" v-for="x in floors[selectedFacility]" :key="x"
                                @click.prevent="setFacilityAndFloor(selectedFacility, x)"
                                :class="selectedFloorInFacility == x ? null : 'not-selected'">{{ x }}</button>
                        </div>
                    </div>
                </div>
                <div class="leaflet-top leaflet-left route-display-box" style="margin-top: 10px; margin-left: 50px" v-if="route">
                    <div class="container">
                        <div class="row">
                            <div class="col-0 route-display-box">
                                <div>
                                    <span class="white-bottom-border">{{ routeStart }}</span>
                                    <span>&nbsp;→&nbsp;</span>
                                    <span class="white-bottom-border">{{ routeDest }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                                <div class="col">
                                    <button class="btn" @click.stop="prevRouteStep">&lt;</button>
                                </div>
                                <div class="col">
                                    <button class="btn" @click.stop="nextRouteStep">&gt;</button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- тут для ПК -->

            <div class="row justify-content-center pb-5 pt-3">
                <div class="col-auto">
                    <button class="btn btn1 btn-primary btn-lg w-40" style="margin-bottom: 10px;" @click="buildRoute()">
                        Построить маршрут
                    </button>
                </div>
                <div class="col-auto">
                    <button class="btn btn1 btn-primary btn-lg w-40" @click="findAuditory()">
                        Найти аудиторию
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MapDrawer from './mapDrawer/MapDrawer.vue';
const { Point } = require('../mapCore');
export default {
    components: {
        MapDrawer
    },
    data() {
        return {
            // Какие этажи в каких корпусах есть
            floors: {
                1: [0, 1, 2, 3, 4],
                2: [0, 1, 2, 3, 4],
                3: [0, 1, 2, 3, 4, 5],
                p: [1, 2],
                4: [1, 2, 3, 4]
            },
            // Представление имён корпусов. Если для корпуса не указано представление, в качестве такового используется код корпуса
            facilityDisplayNames: {
                p: 'П'
            },

            selectedFacility: '1',
            selectedFloorInFacility: '1',
            
            routeStart: null,
            routeDest: null,

            route: null,
            routeStep: -1,
            showFloorSelectBtns: true,
        };
    },
    computed: {
        floor() {
            return `${this.selectedFacility}.${this.selectedFloorInFacility}`;
        }
    },
    watch: {
        floor() {
            this.$refs.drawer.floor = this.floor;
            if (!this.route) this.$refs.drawer.clearAll();
        },
    },
    methods: {
        setFacilityAndFloor(fac, fl) {
            this.selectedFacility = fac;
            this.selectedFloorInFacility = fl === undefined ? this.selectedFloorInFacility : fl;
            if (this.floors[this.selectedFacility].indexOf(this.selectedFloorInFacility) == -1) this.selectedFloorInFacility = this.floors[this.selectedFacility][0];
            this.route = null;
        },
        prevRouteStep() {
            if (this.route[this.routeStep - 1]) {
                this.routeStep--;
                this.drawRoutePart(this.routeStep + 1);
            }
        },
        nextRouteStep() {
            if (this.route[this.routeStep + 1]) {
                this.routeStep++;
                this.drawRoutePart(this.routeStep - 1);
            }
        },
        drawRoutePart(prevStep) {
            this.$refs.drawer.clearAll();
            if (!this.route || !this.route[this.routeStep]) return;
            let floor = Point.list[this.route[this.routeStep]].floorMask.split('.');
            this.selectedFacility = floor[0];
            this.selectedFloorInFacility = floor[1];
            this.$refs.drawer.drawPoint(this.route[this.routeStep]);

            if (prevStep >= this.routeStep) return;
            let prev = this.route[prevStep];
            if (!prev || Point.list[prev].floorMask != this.floor) return;
            let clRoute = Point.list[this.route[this.routeStep]].connectViaCorridorsTo(prev);
            if (!clRoute) return;
            console.table(clRoute);

            clRoute.forEach((curr, i) => {
                let next = clRoute[i + 1];
                if (!next) return;
                this.$refs.drawer.drawCorridorLine(`inner${i}`, {
                    startCoords: { x: curr.x, y: curr.y },
                    endCoords: { x: next.x, y: next.y }
                }, undefined, 'red');
            });
        },
        buildRoute(startName, destName) {
            if (!startName) {
                if (Object.keys(Point.userReadableList).length == 0) return alert('Карта не загружена. Попробуйте обновить страницу');
                startName = prompt('Укажите начальную аудиторию');
                if (!startName) return;
                destName = prompt('Укажите конечную аудиторию');
                if (!destName) return;
            }

            let start = Point.userReadableList[startName];
            let dest = Point.userReadableList[destName];
            if (!start) return alert(`Некорректная начальная точка "${startName}"`);
            if (!dest) return alert(`Некорректная конечная точка "${destName}"`);
            this.routeStart = startName;
            this.routeDest = destName;

            let route = start.buildRoute(dest.id);
            console.table(route);
            if (!route) return alert('Нет маршрута между этими аудиториями');

            this.routeStep = 0;
            this.route = route;
            this.drawRoutePart(0);
        },
        findAuditory(name){
            if (Object.keys(Point.userReadableList).length == 0) return alert('Карта не загружена. Попробуйте обновить страницу');
            if(!name){
                name = prompt('Укажите аудиторию для поиска');
            }
            if (!name) return;
            this.buildRoute(name,name);
        },
    }
};
</script>

<style scoped>
.route-display-box {
    background: #012C4B;
    color: white;
    font-size: 18px;
}
.floor-sel-btn {
    width: 35px;
    font-size: 14px;
}
.leaflet-top, .leaflet-right, .leaflet-left, .leaflet-bottom {
    pointer-events: all;
}

.but {
    font-size: 14px;
}

.btn {
    /* font-size: 15px !important; */
    border: 1px solid #012c4b;
}

.not-selected {
    color: #012c4b;
    background: white;
}

.btn1 {
    background: #012C4B;
    color: white;
    font-size: 25px;
}

.btn2 {
    font-size: 15px !important;
    width: 36px !important;
    background: white;
    color: #012C4B;
    border: 1px solid #012C4B;
}
.btn2:hover {
    background: #012c4b !important;
    color: white;
}

.active1 {
    background: #012c4b;
    color: white;
    border-radius: 5px;
}

.bts {
    float: right;
    right: 0;
    position: absolute;
    font-size: 18px;
}

.map {
    height: 500px;
}

.knp {
    right: 0;
}

.swip {
    width: 60px;
}

.knopochku {
    margin-top: 35px;
}

.b1 {
    float: right;
    right: 0;
    position: absolute;
    font-size: 18px;
    margin-top: 225px;
}

.b2 {
    float: right;

    right: 0;
    position: absolute;
    font-size: 18px;
    width: 38px;
    margin-top: 268px;
}

.pseudo-input {
    border-bottom: 1px solid white;
    cursor: pointer;
}
</style>