<template>
    <span v-if="full">644080, г. Омск, пр. Мира, 5 корп. {{ facility(auds[0]) }}, кабинет </span>
    <span v-for="(aud, i) in auds" :key="i">
        <a href="#" @click.prevent="goToAud(aud)" style="white-space: nowrap;">{{ aud }}</a>{{ auds[i + 1] ? ', ' : '' }}
    </span>
</template>

<script>
export default {
    props: ['auds', 'full'],
    methods: {
        goToAud(aud) {
            let start = prompt('Где вы сейчас?');
            if (!start) return;
            this.$emit('buildRoute', start, aud);
        },
        
        facility(aud) {
            return aud.split('.')[0];
        },
    }
}
</script>

<style>

</style>