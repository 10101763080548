<template>
    <section class="py-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-6">
                    <p class="text display-5"><span class="t1">Навигатор СибАДИ - </span><br/>быстрый поиск аудиторий
                    </p>
                </div>
            </div>
            <br>
            <div class="row justify-content-center">
                <div class="col-6 ">
                    <button class="btn btn1 btn-primary btn-lg w-100 tb" @click="$store.state.currentPage = 'map'">Перейти к карте</button>
                </div>
            </div>
            <br />
            <div class="row justify-content-center">
                <div>
                    <p class="text2 display-5">Расшифровка всех номеров аудиторий</p>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="picture">
                    <img src="img/audNumberDescription/standart.svg" alt="picture" class="img-thumbnail">
                    <!-- <img src="img/audNumberDescription/podval.svg" alt="picture" class="img-thumbnail"> -->
                    <img src="img/audNumberDescription/potochka.svg" alt="picture" class="img-thumbnail">
                </div>
            </div>
        </div>
    </section>
    <section class="py-5 cxhema">
        <div class="container">
            <div class="row justify-content-center">
                <div>
                    <p class="display-5">Схема корпусов СибАДИ</p>
                </div>
                <div>
                    <img src="img/campusScheme.svg" style="max-width: 100%;"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import store from '@/store';
export default {
    store: store
}
</script>

<style scoped>
.text2 {
    font-weight: 500;
    color: #012C4B;
    text-align: center;
}

.text {
    text-align: center;
}

.btn1 {
    background: #012C4B;
    color: white;
    font-size: 25px;
}

.tb {
    font-size: 21px;
}

.picture {
    text-align: center;
}

.cxhema {
    background: #D9D9D9;
}
</style>