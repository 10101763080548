function convertGraphToTree(graph, from) {
    let result = {};
    let visited = [from];
    
    let toBeVisited = [[from, result]];
    while (toBeVisited.length > 0) {
        let raw = toBeVisited.shift();
        const currDot = raw[0];
        const obj = raw[1];
        for (const nextDot of graph[currDot]) {
            if (visited.indexOf(nextDot) != -1) continue;
            visited.push(nextDot);
            if (!obj[currDot]) obj[currDot] = {};
            obj[currDot][nextDot] = {};
            toBeVisited.push([nextDot, obj[currDot]]);
        }
    }
    return result;
}

function convertTreeToRoutes(tree) {
    let result = [];

    for (let i in tree) {
        result.push([i]);
        let subs = convertTreeToRoutes(tree[i]);
        for (const x of subs) {
            result.push([i, ...x]);
        }
    }
    return result;
}

function selectRoute(routes, to) {
    return routes.filter(r => r.at(-1) == to).sort((a, b) => a.length - b.length)[0];
}

function buildRoute(graph, from, to) {
    let s = new Date;
    let tree = convertGraphToTree(graph, from);
    let routes = convertTreeToRoutes(tree);
    let selected = selectRoute(routes, to);
    console.log(`Маршрут построен за ${new Date - s} мс`);
    return selected;
}

module.exports = buildRoute;