const { default: store } = require("./store");

async function makeRequest(method, url, data) {
    let serverUrl = localStorage.serverUrl || 'https://navsibadi.ru:9010';
    let resp = await fetch(`${serverUrl}/${url.replace(/^\//, '')}`, {
        method,
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Sibadi-Access-Token': store.state.user.accessToken || 'None'
        }
    });
    if (resp.status != 200) throw Error(`Сервер ответил статусом ${resp.status} (${resp.statusText})`);
    try {
        let json = await resp.json();
        return json;
    }
    catch { return null; }
}

module.exports = {
    get: (url, data) => makeRequest('GET', url, data),
    post: (url, data) => makeRequest('POST', url, data),
    put: (url, data) => makeRequest('PUT', url, data),
    delete: (url, data) => makeRequest('DELETE', url, data),
}