<template>
    <div class="container-xxl">
        <div class="row mt-3">
            <div class="text-footer2 col-12 col-md-6 d-none d-sm-none d-md-block">
                <ul class="nav flex-column text-footer text-footer2 justify-content-center">
                    <li class="nav-item">ФГБОУ ВО «СибАДИ»</li>
                    <li class="nav-item">644080, г. Омск, пр. Мира 5</li>
                </ul>
            </div>
            <div class="text-footer2 col-md-6 d-none d-sm-none d-md-block">
                <ul class="nav flex-column text-footer text-footer2 justify-content-center ">
                    <li class="nav-item ">8 (3812) 90-94-59</li>
                    <li class="nav-item">Email: info@sibadi.org</li>
                </ul>
            </div>
            <div class="text-light col- col-sm-12 d-md-none d-lg-none text-footer2 justify-content-center">
                ФГБОУ ВО «СибАДИ» 644080, г. Омск, пр. Мира 5 8 (3812) 90-94-59 Email: info@sibadi.org
            </div>
        </div>
        <div class="line2 ">
            <hr>
        </div>
        <p class="text-center text-light">Создано студентами инситута ИСЭиУ <br>
            Для вопросов по проекту: <a href="mailto:navsibadi@ya.ru">navsibadi@ya.ru</a>
        </p>
        <div class="mb-2 text-center image"><img src="img/logo2.png" alt="logo"></div>
    </div>
</template>

<script>
export default {
    name: 'MainFooter'
}
</script>

<style scoped>
.footer {
    background: #012C4B;
}
.text-footer {
    color: white;
    font-weight: 400;
}
.text-footer2 {
    text-align: center;
}
footer {
    flex: 0 0 auto;
}
.line2 {
    color: white;
    background: white;
}
</style>