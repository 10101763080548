const graphRouter = require('./graphRouter');

/*
    Алгоритм построения маршрута
*/

/**
 * Точка на карте
 */
 class Point {
    /**
     * Список точек на карте по их ID. Точки добавляются сюда автоматически при создании через конструктор (new Point(...))
     * @type {Object<string, Point>}
     */
    static list = {};

    /**
     * Список точек на карте по их отображаемому имени. Точки добавляются сюда автоматически при создании через конструктор (new Point(...))
     * @type {Object<string, Point>}
     */
    static userReadableList = {};

    /**
     * Подходит ли id под маску mask? Например, "2.3.56" подходит под маску "2.3.\*", "2.\*.\*" и "\*.\*.\*", но не подходит под "2.4.\*" и "3.3.\*"
     * @param {string} id Проверяемый ID точки
     * @param {string} mask Маска, например "2.3.\*"
     * @returns {boolean} Подходит или нет
     */
    static matchMask(id, mask) {
        id = id.split('.');
        mask = mask.split('.');

        for (let i = 0; i < 3; i++) {
            if (mask[i] == '*') return true;
            if (mask[i] != id[i]) return false;
        }
        return true;
    }

    /**
     * ID точки в формате "корпус.этаж.idТочкиНаЭтаже", например "2.3.56" для ауд. 2.356
     * @type {string}
     */
    id;

    /**
     * Имя точки, которое будет отображаться пользователю
     * @type {string}
     */
    name;

    /**
     * Точки, с которыми соединяется данная
     * @type {string[]}
     */
    connectsTo = [];

    /**
     * Координаты точки, отображаемые в UI
     */
    coords = {
        x: 0,
        y: 0
    };

    /**
     * Корридорная линия
     * @type {string}
     */
    corridor;

    /**
     * Является ли точка аудиторией
     */
    isEndpoint = true;

    /**
     * Группа точек
     */
    group;

    /**
     * Маска этажа данной точки. Пример: тут будет "2.3" для точки с ID "2.3.56"
     */
    get floorMask() {
        return this.id.split('.').slice(0, 2).join('.');
    }

    /**
     * 
     * @param {string} id ID точки
     * @param {string} name Имя точки, отображаемое пользователю
     * @param {string[]} routes Массив точек, с которыми у данной есть соединение
     * @param {{ x : number, y : number } | [ number, number ]} coords Координаты точки для отображения пользователю
     * @param {string} corridor Привязка к корридорной линии
     */
    constructor(id, name, connectsTo, coords, corridor, isEndpoint, group) {
        this.id = id;
        this.name = name;
        this.group = group;
        this.isEndpoint = !!isEndpoint;
        this.connectsTo = connectsTo || [];
        if (Array.isArray(coords)) this.coords = { x: coords[0], y: coords[1] };
        else if (typeof coords == 'object') this.coords = coords;

        Point.list[id] = this;
        if (name) Point.userReadableList[name] = this;
        this.corridor = corridor;
    }

    /**
     * Построить маршрут до точки target
     * @param {string} target
     * @return {string[]?} NULL, если точка target недостижима
     */
    buildRoute(target) {
        if (!Point.list[target]) throw Error(`Точка ${target} не существует`);
        /** @type {Object<string, string[]>} */
        let graph = {};
        for (let i in Point.list) {
            let p = Point.list[i];
            // if (p.isEndpoint && p != this && p.id != target) continue;
            
            if (!graph[p.id]) graph[p.id] = [];
            graph[p.id] = [...graph[p.id], ...p.connectsTo];

            p.connectsTo.forEach(id => {
                if (!graph[id]) graph[id] = [];
                graph[id].push(p.id);
            });
        }
        for (let i in graph) graph[i] = [...new Set(graph[i])];
        let rawRoute = graphRouter(graph, this.id, target, [this.id]);
        rawRoute = rawRoute.map((curr, idx) => {
            let prev = rawRoute[idx - 1];
            let next = rawRoute[idx + 1];
            if (!next || !prev) return curr;
            if (Point.list[next].group && Point.list[prev].group == Point.list[curr].group && Point.list[curr].group == Point.list[next].group) return false;
            else return curr;
        }).filter(x => !!x);
        return rawRoute;
    }

    findCorridorLineNearestPoint() {
        let line = CorridorLine.list[this.corridor];
        if (line.isHoriz) return { x: this.coords.x, y: line.startCoords.y };
        else return { x: line.startCoords.x, y: this.coords.y };
    }

    connectViaCorridorsTo(target) {
        const tp = Point.list[target];
        if (!tp) throw Error(`Точка ${target} не существует`);
        if (this.floorMask != tp.floorMask) throw Error('Точки не на одном этаже');
        let fromThis = this.findCorridorLineNearestPoint();
        let toTarget = tp.findCorridorLineNearestPoint();
        return [
            this.coords,
            fromThis,
            ...CorridorLine.list[this.corridor].routeToLine(tp.corridor),
            toTarget,
            tp.coords
        ];
    }
}

class CorridorLine {
    /** @type {Object<string, CorridorLine>} */
    static list = {};

    id = '';
    startCoords = { x: 0, y: 0 };
    endCoords = { x: 0, y: 0 };

    get isHoriz() {
        return this.startCoords.y == this.endCoords.y;
    }

    get isVertical() {
        return this.startCoords.x == this.endCoords.x;
    }

    /**
     * Маска этажа данной точки. Пример: тут будет "2.3" для точки с ID "2.3.56"
     */
    get floorMask() {
        return this.id.split('.').slice(0, 2).join('.');
    }

    constructor(id, start, end) {
        this.startCoords = start;
        this.endCoords = end;
        this.id = id;
        CorridorLine.list[id] = this;
    }

    /**
     * Найти все соединения с другими линиями (кроме except, если задано)
     * @param {string} except Игнорировать пересечение на определённых координатах
     */
    getJunctions(except) {
        let result = [];
        for (let i in CorridorLine.list) {
            if (this.id == i) continue;
            if (except == i) continue;
            let cl = CorridorLine.list[i];
            if (this.floorMask != cl.floorMask) continue;
            let allCoords = [JSON.stringify(this.startCoords), JSON.stringify(this.endCoords), JSON.stringify(cl.startCoords), JSON.stringify(cl.endCoords)];
            let duplicate = allCoords.filter((item, index) => allCoords.indexOf(item) !== index)[0];
            if (!duplicate) continue;
            if (duplicate == except) continue;
            result.push([i, duplicate]);
        }
        return result;
    }

    /**
     * Найти линии, через которые можно добраться от текущей до target
     * @param {string} target ID конечной линии
     * @param {string} previous Координаты предыдущего соединения линий (используется при рекурсивном вызове функции)
     * @returns {{ x : string, y : string }[]} Массив промежуточных точек
     */
    routeToLine(target, previous) {
        if (this.id == target) return [];
        for (const junc of this.getJunctions(previous)) {
            let subroute = CorridorLine.list[junc[0]].routeToLine(target, junc[1]);
            if (!subroute) continue;
            return [JSON.parse(junc[1]), ...subroute];
        }
        return null;
    }
}

module.exports = {
    Point,
    CorridorLine
};