import { createStore } from 'vuex'

export default createStore({
    state: {
        list: {
            'imagine-this-is-uuid': {
                name: 'Иван Иванов',
                prevs: {
                    mapEditor: true,
                    users: false,
                    schedule: true
                }
            }
        }
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
});