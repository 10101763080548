<template>
    <div class="container xxl mb-3">
        <div class="row" style="font-size: smaller">
            <!-- Выбор даты -->
            <div class="col border rounded blue-card p-1">
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <img src="img/calendar.png" style="width: 40px" />
                    </div>
                    <div class="col-auto ps-0">
                        <div>Начало</div>
                        <div class="plan-pseudo-input">
                            <span @click="selectDateFrom">{{ formatDate(dateFrom) }}</span>
                            <!-- <span class="ms-1">
                                <img src="img/placeholder.png" style="width: 20px" />
                            </span> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col border rounded blue-card p-1">
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <img src="img/calendar.png" style="width: 40px" />
                    </div>
                    <div class="col-auto ps-0">
                        <div>Конец</div>
                        <div class="plan-pseudo-input">
                            <span @click="selectDateTo">{{ formatDate(dateTo) }}</span>
                            <!-- <span class="ms-1">
                                <img src="img/placeholder.png" style="width: 20px" />
                            </span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mt-2" style="font-size: smaller">
            <!-- Выбор откуда расписание (группа, препод и прочее) -->
            <div class="col border rounded blue-card p-1 text-center">
                <div>Группа</div>
                <span class="plan-pseudo-input" @click="setGroup">{{ $store.state.schedule.group || '[Не указана]' }}</span>
            </div>
            <div class="col border rounded blue-card p-1 text-center ms-2">
                <div>Аудитория</div>
                <span class="plan-pseudo-input" @click="setAudFilter">{{ audFilter || '[Ввод аудитории]' }}</span>
            </div>
            <div class="col border rounded blue-card p-1 text-center ms-2">
                <div>Преподаватель</div>
                <span class="plan-pseudo-input" @click="setTeacherFilter">{{ teacherFilter || '[Ввод фамилии]' }}</span>
            </div>
            <div class="col border rounded blue-card p-1 text-center ms-2">
                <div>Семестр</div>
                <span class="plan-pseudo-input">{{ currentSemestr == 1 ? 'Осенний' : 'Весенний' }}</span>
            </div>
            <div class="col border rounded blue-card p-1 text-center ms-2">
                <div>Учебный год</div>
                <span class="plan-pseudo-input">{{ currentAcademicYear }}</span>
            </div>
        </div>

        <div class="text-center" v-if="!$store.state.schedule.group">
            <br />
            <button class="btn" @click="setGroup">Указать группу</button>
        </div>

        <div class="text-center" v-if="$store.state.user.prevs.schedule">
            <br />
            <button class="btn" @click="callScheduleSync">[A] Принудительно обновить расписание</button>
        </div>

        <div v-for="(lessonsOnDt, dt) in groupedLessons" :key="dt">
            <!-- Перебор всех дат в сгруппировонном расписании -->
            <div class="row border rounded blue-card mt-4 p-1">
                <div class="col text-start">
                    {{ daysOfWeek[new Date(+dt).getDay()] }}
                </div>
                <div class="col text-end">{{ formatDate(new Date(+dt)) }}</div>
            </div>

            <div v-for="(lessonsOnNumber, lessonNo) in lessonsOnDt" :key="lessonNo">
                <!-- Перебор всех наборов пар на данную дату -->
                <div class="row mt-2" v-for="(l, idx) in lessonsOnNumber" :key="idx">
                    <!-- Перебор всех пар с одинаковым номером на данную дату -->

                    <!-- Левый столбец -->
                    <div class="col-auto border rounded me-2 text-center"
                        style="border-width: 2px !important; display: flex" :style="{
                            borderColor: lessonColor(l.subject) + '!important',
                            background: idx == 0 ? lessonColor(l.subject) : null,
                        }">
                        <!-- Время начала и время окончания -->
                        <div v-if="idx == 0" style="display: flex; align-items: center">
                            <div style="margin: auto">
                                <div>{{ lessonStart(lessonNo) }}</div>
                                <hr style="margin: 5px 0; width: 50px; opacity: unset" />
                                <div>{{ lessonEnd(lessonNo) }}</div>
                            </div>
                        </div>

                        <!-- Стрелочка вместо времени занятия -->
                        <div v-else>
                            <img class="mt-2" src="img/arrow-up.png" style="width: 50px" />
                        </div>
                    </div>

                    <!-- Правый столбец -->
                    <div class="col border rounded" style="background-color: #eeeeee; border-width: 2px !important"
                        :style="{ borderColor: lessonColor(l.subject) + '!important' }">
                        <div>{{ l.subject }}</div>
                        <br />
                        <div>
                            <img src="img/avatar.png" style="width: 15px" />
                            {{ l.teacher }}
                        </div>
                        <div>
                            <img src="img/mapPoint.png" style="width: 15px" />
                            <a href="#" @click.prevent="audSelected(l.aud)">Аудитория: {{ l.aud }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store';
import { schedule as loadScheduleFromServer } from '../dataLoader';
import api from '@/api';

export default {
    data() {
        return {
            /** Дни недели :) */
            daysOfWeek: [
                "",
                "Понедельник",
                "Вторник",
                "Среда",
                "Четверг",
                "Пятница",
                "Суббота",
                "Воскресенье",
            ],
            dateFrom: new Date(),
            dateTo: new Date(),
            audFilter: null,
            teacherFilter: null,
        };
    },
    computed: {
        currentSemestr() {
            const m = new Date().getMonth();
            if (m >= 8 || m == 0) return 1;
            else return 2;
        },
        currentAcademicYear() {
            const y = new Date().getFullYear();
            if (this.currentSemestr == 1) return `${y} - ${y + 1}`;
            else return `${y - 1} - ${y}`;
        },

        /**
         * Вычисляемое поле, возвращающее значения из this.$store.state.schedule.data, сгруппированные сначала по датам, а затем по номерам пар
         * {
         *      Дата1: { // Все занятия на дату Дата1
         *          Пара1: [<Пара>, <Пара>], //Все занятия на паре Пара1 в дату Дата1 (такая группировка нужна для отображения стрелочек вместо времени в занятиях по, например, физре)
         *          Пара2: [<Пара>]
         *      },
         *      Дата2: {
         *          Пара2: [<Пара>],
         *          Пара3: [<Пара>]
         *      }
         * }
         * @returns {Object<string, Object<string, { subject : string }[]>>}
         */
        groupedLessons() {
            let byDate = {};

            const dates = [...new Set(this.filteredData.map((l) => +l.date))].sort(
                (a, b) => a - b
            );
            for (const dt of dates) {
                let byLessonNo = {};
                const lessonsOnThisDate = this.filteredData
                    .filter((l) => +l.date == dt)
                    .sort((a, b) => a.lessonNo - b.lessonNo);
                for (let lesson of lessonsOnThisDate) {
                    if (!byLessonNo[lesson.lessonNo]) byLessonNo[lesson.lessonNo] = [];
                    byLessonNo[lesson.lessonNo].push(lesson);
                }
                byDate[dt] = byLessonNo;
            }

            return byDate;
        },
        filteredData() {
            return store.state.schedule.data.filter(l => {
                if (this.audFilter && l.aud != this.audFilter) return false;
                if (this.teacherFilter && l.teacher.toLowerCase().indexOf(this.teacherFilter.toLowerCase()) == -1) return false;
                return true;
            });
        }
    },
    store: store,
    methods: {
        audSelected(aud) {
            let start = prompt('Где вы сейчас?');
            if (!start) return;
            this.$emit('buildRoute', start, aud);
        },
        setGroup() {
            let ans = prompt('Укажите свою группу', this.$store.state.schedule.group || '');
            if (!ans) return;
            this.$store.state.schedule.group = ans;
            localStorage.sibadiGroup = ans;
            loadScheduleFromServer();
        },
        callScheduleSync() {
            api.get('schedule/sync');
            alert('Запустили обновление расписания');
        },

        /**
         * Возвращает время начала занятия по номеру пары
         * @param {number} lessonNo Номер пары
         * @returns {string}
         */
        lessonStart(lessonNo) {
            let times = [null, "08:20", "10:00", "11:40", "13:45", "15:25", "17:05"];
            return times[lessonNo];
        },
        /**
         * Возвращает время окончания занятия по номеру пары
         * @param {number} lessonNo Номер пары
         * @returns {string}
         */
        lessonEnd(lessonNo) {
            let times = [null, "09:50", "11:30", "13:10", "15:15", "16:55", "18:35"];
            return times[lessonNo];
        },
        /**
         * Возвращает готовый для применения в CSS цвет карточки занятия в зависимости от вида занятия (лаб/пр/лек)
         * @param {string} subject Имя предмета (например, "лаб Автоматизация процессов")
         * @returns {string}
         */
        lessonColor(subject) {
            let colors = {
                лек: "#b0d376",
                пр: "#f2c2a6",
                лаб: "#d9b9e9",
            };
            return colors[subject.split(" ")[0].replace(/\.$/, '')];
        },
        /**
         * Красиво форматирует дату
         * @param {Date} dt Объект даты
         * @returns {string}
         */
        formatDate(dt) {
            const addZeros = (number, required) => {
                let result = String(number);
                while (result.length < required) result = "0" + result;
                return result;
            };

            let date = dt.getDate();
            let month = dt.getMonth() + 1;
            let year = dt.getFullYear();
            return `${addZeros(date, 2)}.${addZeros(month, 2)}.${year}`;
        },
        setAudFilter() {
            let f = prompt('Введите номер аудитории');
            this.audFilter = f || null;
        },
        setTeacherFilter() {
            let f = prompt('Введите ФИО преподавателя');
            this.teacherFilter = f || null;
        },
        parseDate(raw) {
            raw = raw.split('.');
            let y = +raw[2];
            let m = +raw[1];
            let d = +raw[0];
            return new Date(y, m - 1, d);
        },
        selectDateFrom() {
            let f = prompt('Введите дату в формате "05.12.2022"', this.formatDate(this.dateFrom));
            if (!f || !/\d{2}.\d{2}.\d{4}/.test(f)) return;
            this.dateFrom = this.parseDate(f);
            loadScheduleFromServer(this.dateFrom, this.dateTo);
        },
        selectDateTo() {
            let f = prompt('Введите дату в формате "05.12.2022"', this.formatDate(this.dateTo));
            if (!f || !/\d{2}.\d{2}.\d{4}/.test(f)) return;
            this.dateTo = this.parseDate(f);
            loadScheduleFromServer(this.dateFrom.toISOString(), this.dateTo.toISOString());
        },
    },
    mounted() {
        const today = new Date;
        
        let weekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1);
        if (weekStart < today) weekStart = today;

        const weekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - today.getDay()));

        this.dateFrom = weekStart;
        this.dateTo = weekEnd;
    },
    watch: {
        '$store.state.currentPage'() {
        }
    },
};
</script>

<style scoped>
.thick-border {
    border-width: 2px !important;
}

.blue-card {
    background-color: #012e4b;
    color: white;
}

.plan-pseudo-input {
    border-bottom: 1px solid white;
    cursor: pointer;
}
</style>