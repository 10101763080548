<template>
  <div id="leaflet" :style="{ height: (height || 300) + 'px' }"></div>
</template>

<script>
const L = require('leaflet');
const { Point, CorridorLine } = require('../../mapCore');
/** Объект Leaflet */
let map;
/** Объект слоя Leaflet */
let currentLayer;
/** Указатели на объекты, отрисованные на карте */
let drawnObjects = {};

export default {
    props: ['height'],
    data() {
        return {
            showPoints: [],
            showCorridorLines: [],
            floor: '1.1'
        };
    },
    methods: {
        /**
         * Вывести изображение в качестве фона слоя. Используется для отрисовки нужного этажа
         * @param {string} src Путь к изображению
         */
        drawFloor(src) {
            if (currentLayer) currentLayer.removeFrom(map);
            currentLayer = L.imageOverlay(src, [[0, 0], [500, 500]]).addTo(map);
        },
        /**
         * Вывести на карту точку
         * @param {*} id ID точки
         * @param {*} data Данные точки. Указываются только для ещё не добавленной в базу точки (в редакторе)
         * @param {*} popup Текст, выводящийся при клике на точку
         * @param {*} color Цвет точки
         */
        drawPoint(id, data, popup, color) {
            this.removeDrawnObject('p:' + id);
            if (!data) data = Point.list[id];
            let marker = L.marker(L.latLng([data.coords.y, data.coords.x]), { color: color || '#0d6efd' });
            if (popup) marker.bindPopup(popup);
            marker.on('click', () => this.$emit('markerClick', id));
            marker.addTo(map);
            drawnObjects['p:' + id] = marker;
        },
        /**
         * Вывести на карту корридорную линию
         * @param {*} id ID
         * @param {*} data Данные линии. Указываются только для ещё не добавленной в базу линии (в редакторе)
         * @param {*} popup Текст, выводящийся при клике на линию
         * @param {*} color Цвет линии
         */
        drawCorridorLine(id, data, popup, color) {
            this.removeDrawnObject('cl:' + id);
            if (!data) data = CorridorLine.list[id];
            let line = L.polyline([[data.startCoords.y, data.startCoords.x], [data.endCoords.y, data.endCoords.x]], { color: color || '#0d6efd' });
            if (popup) line.bindPopup(popup);
            line.on('click', () => this.$emit('corrLineClick', id));
            line.addTo(map);
            drawnObjects['cl:' + id] = line;
        },
        /**
         * Удалить объект по его ID
         * @param {string} id ID объекта
         */
        removeDrawnObject(id) {
            if (!drawnObjects[id]) return;
            drawnObjects[id].removeFrom(map);
            delete drawnObjects[id];
        },
        /**
         * Удалить все объекты, выведенные на карту
         */
        clearAll() {
            for (let i in drawnObjects) {
                this.removeDrawnObject(i);
            }
        }
    },
    watch: {
        // Отрисовывает этаж при изменении свойства floor
        floor() {
            this.drawFloor(`mapSvg/${this.floor}.svg`);
        }
    },
    // Инициализация Leaflet и карты
    mounted() {
        map = L.map('leaflet', {
            attributionControl: false,
            crs: L.CRS.Simple
        });
        map.setView([250, 250], 1);
        map.on('click', e => {
            this.$emit('coords', +(e.latlng.lng).toFixed(2), +(e.latlng.lat).toFixed(2));
        });

        this.drawFloor(`mapSvg/1.1.svg`);
    }
}
</script>

<style>

</style>