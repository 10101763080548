<template>
    <div class="container">
        <h2>Новый пользователь</h2>
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">Имя:</span>
            </div>
            <input class="form-control" v-model="newUser.name"/>
        </div>
        <h4>Права:</h4>
        <div v-for="(name, i) in prevNames" :key="i">
            <label><input type="checkbox" v-model="newUser.prevs[i]"/>{{ name }}</label>
        </div>
        <div><button class="btn" @click="createUser">Создать пользователя</button></div>
        <hr />

        <h2>Существующие пользователи</h2>
        <div v-for="(user, uid) in $store.state.users.list" :key="uid">
            <h4>{{ user.name }}</h4>
            <div>Права: {{ Object.keys(user.prevs).filter(i => user.prevs[i] == true).map(i => prevNames[i]).join(', ') || 'НЕ ВЫДАНЫ!' }}</div>
            <div>
                <button class="btn" @click="newAT(uid)">Выпустить access token</button>
                <button class="btn" @click="revokeATs(uid)">Отозвать все access token'ы</button>
                <button class="btn" @click="destroy(uid)">Удалить юзера</button>
            </div>
            <br />
        </div>
    </div>
</template>

<script>
import store from '@/store';
import { users as loadUsers } from '../dataLoader';
const api = require('../api');

export default {
    data() {
        return {
            newUser: {
                name: '',
                prevs: {
                    mapEditor: false,
                    users: false,
                    schedule: false
                },
            },
            prevNames: {
                mapEditor: 'Редактор карт',
                schedule: 'Управление расписанием',
                users: 'Управление пользователями (ОПАСНО)',
            },
        }
    },
    store: store,
    methods: {
        async newAT(uid) {
            let resp;
            try {
                resp = await api.post(`users/accessToken/${uid}`);
            }
            catch (e) {
                alert('Ошибка: ' + String(e))
                return;
            }
            prompt('Вот новый токен для этого юзера:', resp.token);
        },
        async revokeATs(uid) {
            try {
                await api.delete(`users/allAccessTokens/${uid}`);
            }
            catch (e) {
                alert('Ошибка: ' + String(e))
                return;
            }
            alert('Успешно отозваны все токены');
        },
        async destroy(uid) {
            const doubleCheckStr = `Я хочу удалить пользователя ${store.state.users.list[uid].name}`;
            if (prompt(`Введите "${doubleCheckStr}" (точь-в-точь, без кавычек) в строку ниже, чтобы УДАЛИТЬ пользователя из БД`) != doubleCheckStr) return alert('Проверка не пройдена, НЕ удаляем :)');
            try {
                await api.delete(`users/user/${uid}`);
            }
            catch (e) {
                alert('Ошибка: ' + String(e))
                return;
            }
            alert('Успешно удалили юзера');
            loadUsers();
        },
        async createUser() {
            this.newUser.name = this.newUser.name.trim();
            if (!this.newUser.name) return;

            try {
                await api.post(`users/user`, {
                    name: this.newUser.name,
                    prevs: Object.keys(this.newUser.prevs).filter(i => this.newUser.prevs[i] == true)
                });
            }
            catch (e) {
                alert('Ошибка: ' + String(e))
                return;
            }
            alert('Успешно добавили юзера');
            loadUsers();
        }
    }
}
</script>

<style>
.btn {
    background: #012C4B;
    color: white;
}
</style>