<template>
    <div>
        <h4>Редактор корридорных линий</h4>
        <div v-if="locked">Заблокирован - активен редактор точек</div>
        <div v-else-if="editing">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">ID:</span>
                </div>
                <input class="form-control" v-model="id" :disabled="!isNew"/>
            </div>
            
            <div>
                Координаты начала:
                <span v-if="x0 != -1">({{ x0 }}; {{ y0 }})</span>
                <span v-else><b>НЕ ВЫБРАНЫ (щёлкните по карте для выбора)</b></span>
            </div>
            
            <div>
                Координаты конца:
                <span v-if="x1 != -1">({{ x1 }}; {{ y1 }})</span>
                <span v-else><b>НЕ ВЫБРАНЫ ({{ x0 == -1 ? 'сначала выберите первые' : 'щёлкните по карте для выбора' }})</b></span>
            </div>
            
            <div>
                <button class="btn" @click="submit">Подтвердить</button>
            </div>
            <div v-if="!isNew">
                <button class="btn" @click="remove">Удалить линию</button>
            </div>
            <div>
                <button class="btn" @click="quit">Отменить</button>
            </div>
        </div>
        <div v-else>
            <div><button class="btn" @click="addNew">Новая линия...</button></div>
            <div>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">ID существующей:</span>
                </div>
                <input class="form-control" v-model="editingId" @keydown.enter="editExisting"/>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
const { /* Point, */ CorridorLine } = require('../../mapCore');

/**
 * Выравнивает координаты корридорной линии для соблюдения её полной горизонтальности/вертикальности. Также позволяет стыковать линии между собой
 * @param {*} x0 X начальной точки
 * @param {*} x1 X конечной точки
 * @param {*} y0 Y начальной точки
 * @param {*} y1 Y конечной точки
 * @param {*} floor Текущий этаж
 */
function prepareCoords(x0, x1, y0, y1, floor) {
    const distanceBetweenPoints = (xfrom, yfrom, xto, yto) => Math.sqrt((xfrom - xto) ** 2 + (yfrom - yto) ** 2);
    let isVertical = Math.abs(x0 - x1) < Math.abs(y0 - y1);

    let nearestEdgeOfAnotherLine = null;
    for (let i in CorridorLine.list) {
        if (!i.startsWith(floor + '.')) continue;
        let cl = CorridorLine.list[i];
        let distStart = distanceBetweenPoints(x0, y0, cl.startCoords.x, cl.startCoords.y);
        let distEnd = distanceBetweenPoints(x0, y0, cl.endCoords.x, cl.endCoords.y);
        console.log(cl.id, distStart, distEnd);
        if (distStart < 10 && (!nearestEdgeOfAnotherLine || distStart < nearestEdgeOfAnotherLine[2])) {
            nearestEdgeOfAnotherLine = [cl.startCoords.x, cl.startCoords.y, distStart];
        }
        else console.log('start not');
        if (distEnd < 10 && (!nearestEdgeOfAnotherLine || distEnd < nearestEdgeOfAnotherLine[2])) {
            nearestEdgeOfAnotherLine = [cl.endCoords.x, cl.endCoords.y, distEnd];
        }
        else console.log('end not');
    }
    if (nearestEdgeOfAnotherLine) {
        x0 = nearestEdgeOfAnotherLine[0];
        y0 = nearestEdgeOfAnotherLine[1];
    }

    if (isVertical) x1 = x0;
    else y1 = y0;

    return { x0, y0, x1, y1 };
}

export default {
    data() {
        return {
            // Недоступен
            locked: false,
            // Запущен
            editing: false,

            // Эта новая линия или существующая
            isNew: false,
            // ID новой линии. Вводится вручную
            id: null,
            x0: -1,
            y0: -1,
            x1: -1,
            y1: -1,
            //ID редактируемой существующей линии
            editingId: '',
            floor: '1.1'
        }
    },
    watch: {
        editing() {
            if (!this.editing) {
                this.$emit('ended-editing');
                this.isNew = false;
                this.id = null;
                this.x0 = -1;
                this.x1 = -1;
                this.y0 = -1;
                this.y1 = -1;
            }
        },
        floor() {
            this.quit();
        }
    },
    methods: {
        /**
         * Обработчик выбора координат. Вызывается из дочерних компонентов страницы
         * @param {string} x
         * @param {string} y
         */
        coordsSelected(x, y) {
            if (!this.editing) return;
            if (this.x0 == -1 || this.x1 != -1) {
                this.x0 = x;
                this.y0 = y;

                this.x1 = -1;
                this.y1 = -1;
            }
            else {
                const prepared = prepareCoords(this.x0, x, this.y0, y, this.floor);
                this.x0 = prepared.x0;
                this.x1 = prepared.x1;
                this.y0 = prepared.y0;
                this.y1 = prepared.y1;

                this.$emit('tempCorridorLine', this.x0, this.y0, this.x1, this.y1);
            }
        },
        addNew() {
            this.$emit('startedEditing');
            this.editing = true;
            this.isNew = true;
        },
        submit() {
            if (!this.id) return alert('Вы не указали ID');
            if (this.isNew && CorridorLine.list[this.id]) return alert('Такая линия уже сществует');
            if (this.x0 == -1 || this.x1 == -1) return alert('Вы не выбрали координаты');
            this.$emit('submitLine', this.id, { x: this.x0, y: this.y0 }, { x: this.x1, y: this.y1 });
        },
        editExisting(external) {
            if (typeof external == 'string') this.editingId = external;
            let cl = CorridorLine.list[this.editingId];
            if (!cl) return alert('Линия с таким ID не существует');
            this.$emit('startedEditing');
            this.editing = true;
            this.isNew = false;
            this.id = cl.id;
            this.x0 = cl.startCoords.x;
            this.y0 = cl.startCoords.y;
            this.x1 = cl.endCoords.x;
            this.y1 = cl.endCoords.y;
        },
        quit() {
            this.editing = false;
        },
        remove() {
            this.$emit('removeLine', this.id);
        }
    }
}
</script>

<style>

</style>