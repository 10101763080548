<template>
    <li v-if="styleVariant == 'pc'" class="px-2 link" :class="$store.state.currentPage == this.page ? 'current-nav-link' : null" @click="$store.state.currentPage = page">{{ name }}</li>
    <a v-if="styleVariant == 'mobileDropdown'" @click="$store.state.currentPage = page">{{ name }}</a>
</template>

<script>
import store from '@/store'
export default {
    store: store,
    props: ['page', 'name', 'styleVariant']
}
</script>

<style scoped>
.link {
    text-decoration: none;
    color: #012E4B;
    font-size: 14pt;
    cursor: pointer;
    border-radius: 5px;
}
.link:hover {
    background: #012E4B;
    color: white;
}
.current-nav-link {
    background: #012E4B;
    color: white;
    cursor: unset;
}

a {
    color: #012E4B;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
</style>