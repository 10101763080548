<template>
    <div class="container-xxl">
        <div class="d-flex justify-content-between flex-wrap">
            <div class="col-lg-4 col-md-1 col-sm-1 col-xs-1 col-1 py-3">
                <div class="image"><img src="img/logo.svg"  @click="$store.state.currentPage = 'main'" alt="logo"></div>
            </div>
        <div class="rounded-end col-lg-8 col-md-8 col-sm-none col-12 d-none d-sm-none d-md-none d-lg-flex py-4 justify-content-md-end justify-content-lg-end">
            <nav class="nav">
                <ul class="list-unstyled d-flex p-0 m-0">
                    <NavBtn styleVariant="pc" name="Главная" page="main"></NavBtn>
                    <NavBtn styleVariant="pc" name="Карты" page="map"></NavBtn>
                    <NavBtn styleVariant="pc" name="Расписание" page="schedule"></NavBtn>
                    <NavBtn styleVariant="pc" name="Контакты" page="contacts"></NavBtn>
                    <!-- <NavBtn styleVariant="pc" name="Ответы" page="faq"></NavBtn> -->
                    <NavBtn styleVariant="pc" name="Помощь" page="help"></NavBtn>
                    <NavBtn styleVariant="pc" v-if="$store.state.user.prevs.mapEditor" name="Редактор карты" page="mapEditor"></NavBtn>
                    <NavBtn styleVariant="pc" v-if="$store.state.user.prevs.users" name="Пользователи" page="users"></NavBtn>
                </ul> 
            </nav>
        </div>
        <div class="col-lg-none col-md-none col-sm-1 col-1 d-flex d-sm-flex d-md-flex d-lg-none py-3 justify-content-md-end justify-content-lg-end rounded-end">
            <div class="dropdown">
                <img class="dropbtn polosko rounded-end justify-content-md-end justify-content-lg-end rounded-end" @click="toggleMobileDropdown" src="img/pl.svg" alt="poloco4ki">
                <div ref="mobileDropdown" class="dropdown-content mt-1">
                    <NavBtn styleVariant="mobileDropdown" name="Главная" page="main"></NavBtn>
                    <NavBtn styleVariant="mobileDropdown" name="Карты" page="map"></NavBtn>
                    <NavBtn styleVariant="mobileDropdown" name="Расписание" page="schedule"></NavBtn>
                    <NavBtn styleVariant="mobileDropdown" name="Контакты" page="contacts"></NavBtn>
                    <!-- <NavBtn styleVariant="mobileDropdown" name="Ответы" page="faq"></NavBtn> -->
                    <NavBtn styleVariant="mobileDropdown" name="Помощь" page="help"></NavBtn>
                    <NavBtn styleVariant="mobileDropdown" v-if="$store.state.user.prevs.mapEditor" name="Редактор карты" page="mapEditor"></NavBtn>
                    <NavBtn styleVariant="mobileDropdown" v-if="$store.state.user.prevs.users" name="Пользователи" page="users"></NavBtn>
                </div>
            </div>
        </div>
        </div>
        <div class="line"><hr></div>
    </div>
</template>

<script>
import NavBtn from './HeaderNavButton.vue';

export default {
    name: 'MainHeader',
    components: {
        NavBtn
    },
    data() {
        return {
            //
        }
    },
    methods: {
        toggleMobileDropdown() {
            this.$refs.mobileDropdown.classList.toggle("show");
        }
    },
    watch: {
        '$store.state.currentPage'() {
            this.$refs.mobileDropdown.classList.remove("show");
        }
    }
}
</script>

<style scoped>

.nav {
    font-weight: 500;
}

.dropbtn {
    color: white;
    /* padding: 16px; */
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    right: 7.6%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000000;
}
.show {
    display:block;
}

.line {
    color: #012E4B;
    background: #012E4B;
    height: 2px;
    margin-top: -23px;
} 
</style>