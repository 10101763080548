<template>
    <div>Загрузка: {{ step }}...</div>
</template>

<script>
import store from '@/store';
import { schedule as loadSchedule, currentUser as loadCurrentUser, users as loadUsers, map as loadMap, contacts as loadContacts } from '../dataLoader';
export default {
    data() {
        return {
            step: 'ничего'
        }
    },
    async mounted() {
        this.step = 'текущий пользователь';
        try {
            await loadCurrentUser();
            console.log(`Загрузили ${this.step}`);
        }
        catch (e) {
            alert(`Не удалось загрузить ${this.step}: ${e}`);
            console.error(this.step, e);
        }

        this.step = 'расписание';
        try {
            await loadSchedule();
            console.log(`Загрузили ${this.step}`);
        }
        catch (e) {
            alert(`Не удалось загрузить ${this.step}: ${e}`);
            console.error(this.step, e);
        }

        this.step = 'карта корпусов';
        try {
            await loadMap();
            console.log(`Загрузили ${this.step}`);
        }
        catch (e) {
            alert(`Не удалось загрузить ${this.step}: ${e}`);
            console.error(this.step, e);
        }

        this.step = 'пользователи';
        try {
            await loadUsers();
            console.log(`Загрузили ${this.step}`);
        }
        catch (e) {
            alert(`Не удалось загрузить ${this.step}: ${e}`);
            console.error(this.step, e);
        }

        this.step = 'контакты';
        try {
            await loadContacts();
            console.log(`Загрузили ${this.step}`);
        }
        catch (e) {
            alert(`Не удалось загрузить ${this.step}: ${e}`);
            console.error(this.step, e);
        }

        this.step = 'закончили';
        this.$emit('complete');
    },
    store: store
}
</script>

<style>

</style>