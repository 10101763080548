<template>
    <header>
        <MainHeader></MainHeader>
    </header>
    <main class="mb-3">
        <DataLoader v-if="!dataLoaded" @complete="dataLoaded = true"></DataLoader>
        <div v-else>
            <PageMain v-if="$store.state.currentPage == 'main'"></PageMain>
            <PageMap v-if="$store.state.currentPage == 'map'" ref="pageMap"></PageMap>
            <PageSchedule v-if="$store.state.currentPage == 'schedule'" @build-route="proxyRouteToMap"></PageSchedule>
            <PageMapEditor v-if="$store.state.currentPage == 'mapEditor'"></PageMapEditor>
            <PageContacts v-if="$store.state.currentPage == 'contacts'" @build-route="proxyRouteToMap"></PageContacts>
            <PageFAQ v-if="$store.state.currentPage == 'faq'"></PageFAQ>
            <PageHelp v-if="$store.state.currentPage == 'help'"></PageHelp>
            <PageUsers v-if="$store.state.currentPage == 'users'"></PageUsers>
        </div>
    </main>
    <footer>
        <MainFooter></MainFooter>
    </footer>
    
</template>

<script>
import MainHeader from './components/Header.vue';
import MainFooter from './components/Footer.vue';
import PageMain from './components/PageMain.vue';
import PageSchedule from './components/PageSchedule.vue';
import PageMap from './components/PageMap.vue';
import PageMapEditor from './components/MapEditor/PageMapEditor.vue';
import DataLoader from './components/DataLoader.vue';
import PageContacts from './components/pageContacts/PageContacts.vue';
import PageFAQ from './components/PageFAQ.vue';
import PageHelp from './components/PageHelp.vue';
import PageUsers from './components/PageUsers.vue';
import store from './store';

export default {
    name: 'App',
    components: {
        MainHeader,
        MainFooter,
        PageMain,
        PageMap,
        PageSchedule,
        PageMapEditor,
        DataLoader,
        PageContacts,
        PageFAQ,
        PageHelp,
        PageUsers,
    },
    store: store,
    mounted() {
        this.setPageTitle();
    },
    data() {
        return {
            dataLoaded: false
        }
    },
    methods: {
        /**
         * Вызывается из разных менюшек. Запрос на построение маршрута
         * @param {string} start Начальная точка
         * @param {string} dest Конечная точка
         */
        proxyRouteToMap(start, dest) {
            console.log(start, dest);
            store.state.currentPage = 'map';
            setTimeout(() => this.$refs.pageMap.buildRoute(start, dest), 250);
        },
        setPageTitle() {
            const titles = {
                main: 'Главная',
                map: 'Карта',
                schedule: 'Расписание',
                contacts: 'Контакты',
                faq: 'Ответы',
                help: 'Помощь',
                mapEditor: '[A] Редактор карты',
                users: '[A] Пользователи'
            };
            document.title = titles[this.$store.state.currentPage] || 'Помощник первокурсника';
        },
    },
    watch: {
        '$store.state.currentPage'() {
            this.setPageTitle();
        }
    }
}

</script>

<style scoped>

</style>
