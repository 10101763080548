<template>
<section class="py-5">
        <div class="container-xxl">
            <div class="row">
                <div class="justify-content-center">
                    <p class="head display-5">Инструкция пользователя</p>
                </div>
                <div class="answers">
                    <div class="subHead display-6">Аннотация</div>
                    <div class="text display-7">Настоящий документ представляет собой руководство пользователя (далее Руководство) информационной системы навигации по СибАДИ.</div>
                    <div class="text display-7">Перед работой с ИС навигации по СибАДИ рекомендуется внимательно ознакомиться с настоящим руководством.</div>
                    <div class="text display-7">Информация</div>
                    
                    <div class="subHead display-6 mt-5">Введение</div>
                    <div class="text display-7"><em><u>Краткое описание возможностей</u></em></div>
                    <div class="text display-7">ИС навигации по СибАДИ поможет составить маршрут до нужной аудитории. Есть возможность посмотреть расписание пар. Есть вкладка с контактной информацией.</div>
                    <div class="text display-7 mt-2"><em><u>Список обозначений и сокращений</u></em></div>
                    <div class="text display-7"><em>ИС</em> - информационная система</div>
                    <div class="text display-7"><em>СибАДИ</em> - Сибирский государственный автомобильно-дорожный университет</div>
                    
                    <div class="subHead display-6 mt-5">Описание операций</div>
                    <div class="text display-7"><em>Главная</em> - основная информация про ИС</div>
                    <div class="text display-7"><em>Карты</em> - карты с расположением аудиторий и возможностью построения маршрута до них</div>
                    <div class="text display-7"><em>Расписание</em> - расписание пар с номерами аудиторий</div>
                    <div class="text display-7"><em>Контакты</em> - контактная информация (название, директор/начальник, адрес, телефон, е-майл) об институтах и структурных подразделениях</div>
                    <div class="text display-7"><em>Ответы</em> - часто задаваемые вопросы и ответы на них, форма для вопросов</div>
                    <div class="text display-7"><em>Помощь</em> - руководство пользователя, форма для обратной связи</div>
                    
                    <div class="text display-7 mt-2"><em><u>Поиск расписания для неавторизованного пользователя</u></em></div>
                    <ol class="text display-7">
                        <li>Перейдите на вкладку <strong>Расписание</strong>.</li>
                        <li>Выберите <strong>дату</strong>.</li>
                        <li>Введите <strong>шифр группы</strong>.</li>
                        <li>При необходимости введите <strong>аудиторию</strong> или <strong>ФИО преподавателя</strong>.</li>
                        <li>При переходе по активной ссылке в расписании с <strong>номером аудитории</strong>, совершится переход на вкладку <strong>Карты</strong>, где можно построить маршрут до аудитории.</li>
                    </ol>
                    
                    <div class="text display-7 mt-2"><em><u>Составление маршрута для неавторизованного пользователя </u></em></div>
                    <ol class="text display-7">
                        <li>Перейдите на вкладку <strong>Карты</strong>.</li>
                        <li>Нажмите на кнопку <strong>Построить маршрут</strong>.</li>
                        <li>Введите номер аудитории, <strong>откуда</strong> нужно построить маршрут.</li>
                        <li>Введите номер аудитории, <strong>куда</strong> нужно построить маршрут.</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>

    <section class="py-5">
        <div class="container">
            <p class="head display-5">Обратная связь</p>
            <br>
            <div class="row justify-content-center">
                <div class="col col-lg-5 col-md-5 col-sm-6 col-12">
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="nameInput" v-model="feedbackForm.name" placeholder="Иван">
                        <label for="nameInput">Имя</label>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="emailInput" placeholder="name@example.com" v-model="feedbackForm.email">
                        <label for="emailInput">Адрес электронной почты</label>
                    </div>
                    <div class="form-floating">
                        <textarea class="form-control" style="min-height: 200px;" placeholder="Leave a comment here" id="floatingTextarea" v-model="feedbackForm.body"></textarea>
                        <label for="floatingTextarea">Сообщение</label>
                    </div>
                    
                </div>
            </div>
            <br>
            <div class="row justify-content-center">
                <div class="col col-lg-5 col-md-5 col-sm-6">
                    <button class="btn btn1 btn-primary btn-lg w-100" @click="sendFeedback">Отправить</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import store from '@/store';
import { currentUser as loadCurrentUser } from '../dataLoader';
const api = require('../api');

export default {
    data() {
        return {
            feedbackForm: {
                name: '',
                email: '',
                body: ''
            }
        };
    },
    methods: {
        async sendFeedback() {
            if (this.feedbackForm.email == 'auth') return this.authUser();
            try {
                await api.post('/feedback', {
                    name: String(this.feedbackForm.name),
                    email: String(this.feedbackForm.email),
                    body: String(this.feedbackForm.body),
                });
            }
            catch (e) {
                console.error(e);
                alert('Произошла ошибка :(');
                return;
            }
            alert('Ваше сообщение отправлено. Спасибо :)');
        },
        async authUser() {
            localStorage.sibadiAdminAccessToken = this.feedbackForm.name.trim();
            try {
                await loadCurrentUser(true);
            }
            catch {
                //
            }
            this.feedbackForm.name = '';
            this.feedbackForm.email = '';
        }
    },
    store: store
}
</script>

<style scoped>
.head {
    font-weight: 500;
    text-align: center;
    color: #012E4B;
}
.subHead {
    font-weight: 400;
    color: #012E4B;
}
.text {
    font-size: 25px !important;
    /* text-align: justify; */
    color: #012E4B;
}

.btn1 {
    background: #012C4B;
    color: white;
    font-size: 25px;
}
</style>