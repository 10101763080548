import { createStore } from 'vuex'

export default createStore({
    state: {
        id: null,
        name: null,
        accessToken: null,
        prevs: {
            mapEditor: false,
            users: false
        },
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
});