<template>
<section class="py-5">
        <div class="container-xxl">
            <div class="row">
                <div class="justify-content-center">
                    <p class="text1Answer display-5">Ответы на самые частые вопросы</p>
                </div>
                <div class="answers">
                    <p class="text2Answer display-6">Что такое Интерактивный справочник?</p>
                    <p class="text3Answer display-7">Это просмотр карты корпусов, построение маршрута до аудитории, проверка расписания и получения ответов на вопросы.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.text1Answer {
    font-weight: 500;
    text-align: center;
    color: #012E4B;
}
.text2Answer {
    font-weight: 400;
    color: #012E4B;
}
.text3Answer {
    font-size: 25px !important;
    text-align: justify;
    color: #012E4B;
}

.btn1 {
    background: #012C4B;
    color: white;
    font-size: 25px;
}
</style>