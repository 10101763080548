import { createStore } from 'vuex';
import MapStore from './map';
import CurrentUserStore from './user';
import ScheduleStore from './schedule';
import UsersStore from './users';
import ContactsStore from './contacts';

export default createStore({
    state: {
        currentPage: 'main'
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        map: MapStore,
        user: CurrentUserStore,
        schedule: ScheduleStore,
        users: UsersStore,
        contacts: ContactsStore,
    },
});