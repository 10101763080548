const api = require('./api');
const { Point, CorridorLine } = require('./mapCore');
import store from '@/store';

export async function schedule(from, to) {
    if (!localStorage.sibadiGroup) return;
    store.state.schedule.group = localStorage.sibadiGroup;

    if (!store.state.schedule.group) return;
    let result;
    try {
        result = await api.get(`/schedule/${store.state.schedule.group}${from && to ? `/${from}/${to}` : ''}`);
    }
    catch (e) {
        console.error(e);
        alert(`Не удалось получить расписание: ${e}`);
    }
    store.state.schedule.data = result.data.map(l => {
        l.date = new Date(l.date);
        return l;
    });
}

export async function map() {
    let json;
    try {
        json = await api.get('map/all');
    }
    catch (e) {
        alert(String(e));
        return;
    }
    for (let i in Point.list) delete Point.list[i];
    for (let i in CorridorLine.list) delete CorridorLine.list[i];
    json.points.forEach(raw => new Point(raw.id, raw.displayName, raw.connectsTo, raw.coords, raw.corridorLine, raw.isEndpoint, raw.group));
    json.corridorLines.forEach(raw => new CorridorLine(raw.id, raw.start, raw.end));
    console.log(`Загружено точек карты: ${json.points.length}`);
    console.log(`Загружено корридорных линий карты: ${json.corridorLines.length}`);
}

export async function currentUser(firstTime) {
    if (!localStorage.sibadiAdminAccessToken) return;
    store.state.user.accessToken = localStorage.sibadiAdminAccessToken;
    try {
        let resp = await api.get('/whoami');
        console.log('Юзер обнаружен');
        store.state.user.id = resp.id;
        store.state.user.name = resp.name;
        store.state.user.prevs = resp.prevs;
        if (firstTime) alert(`Авторизовались как ${store.state.user.name}`);
    }
    catch (e) {
        console.log('Юзер не обнаружен', e);
        store.state.user.id = null;
        store.state.user.name = null;
        store.state.user.prevs = {};
        delete localStorage.sibadiAdminAccessToken;
        alert(firstTime ? 'Данные авторизации недействительны' : 'Данные авторизации недействительны. Пройдите авторизацию повторно');
    }
}

export async function users() {
    if (!store.state.user.prevs.users) return;
    store.state.user.accessToken = localStorage.sibadiAdminAccessToken;
    try {
        let resp = await api.get('/users/all');
        if (resp) {
            store.state.users.list = resp.list;
        }
    }
    catch (e) {
        //
    }
}

export async function contacts() {
    store.state.user.accessToken = localStorage.sibadiAdminAccessToken;
    try {
        let resp = await api.get('/contacts/all');
        if (resp) {
            store.state.contacts.institutes = resp.institutes;
            store.state.contacts.cathedras = resp.cathedras;
        }
    }
    catch (e) {
        //
    }
}