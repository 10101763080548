<template>
    <div class="input-group">
        <div class="input-group-prepend">
            <span class="input-group-text">Этаж:</span>
        </div>
        <input class="form-control" @keydown.enter="setFloor" v-model="floorUserInput"/>
    </div>
    <MapDrawer height="500" @coords="coordsSelected" @point-click="pointClicked" @corr-line-clicked="corrLineClicked" ref="drawer"></MapDrawer>

    <div v-if="haveUnsavedChanges">
        <span style="color: red">Есть несохранённые изменения</span>
        <button class="btn" @click="submitChanges">Отправить на сервер</button>
    </div>

    <PointEditor ref="pointEditor"
        @started-editing="setCorridorLineEditorLock(true)"
        @ended-editing="setCorridorLineEditorLock(false)"
        @temp-point="tempPoint"
        @submit-point="pointsubmitted"
        @remove-point="pointRemoved"></PointEditor>
    <hr />
    <CorridorLineEditor ref="corridorLinesEditor" 
        @started-editing="setPointEditorLock(true)"
        @ended-editing="setPointEditorLock(false)"
        @temp-corridor-line="tempCorridorLine"
        @submit-line="linesubmitted"
        @remove-line="lineRemoved"></CorridorLineEditor>
</template>

<script>
import MapDrawer from '../mapDrawer/MapDrawer.vue';
import PointEditor from './PointEditor.vue';
import CorridorLineEditor from './CorridorLineEditor.vue';
const { Point, CorridorLine } = require('../../mapCore');
const api = require('../../api');
window.Point = Point;

export default {
    data() {
        return {
            // Текущий этаж
            floor: '1.1',
            // Значение поля ввода этажа
            floorUserInput: '1.1',
            haveUnsavedChanges: false
        };
    },
    components: {
        MapDrawer,
        PointEditor,
        CorridorLineEditor
    },
    methods: {
        async submitChanges() {
            let points = Object.values(Point.list).map(p => ({ id: p.id, displayName: p.name, connectsTo: p.connectsTo, coords: p.coords, corridorLine: p.corridor, isEndpoint: p.isEndpoint, group: p.group }));
            let corridorLines = Object.values(CorridorLine.list).map(cl => ({ id: cl.id, start: cl.startCoords, end: cl.endCoords }));

            try {
                await api.put('map/all', { points, corridorLines });
            }
            catch (e) {
                return alert(String(e));
            }
            this.haveUnsavedChanges = false;
            alert('Всё ок');
        },
        /**
         * Перерисовать карту
         */
        resetMap() {
            this.$refs.drawer.clearAll();
            for (let i in Point.list) {
                const p = Point.list[i];
                if (i.startsWith(this.floor + '.')) {
                    this.$refs.drawer.drawPoint(i, undefined, `${p.name} (ID: ${p.id})`);
                }
            }
            for (let i in CorridorLine.list) {
                if (i.startsWith(this.floor + '.')) {
                    const cl = CorridorLine.list[i];
                    this.$refs.drawer.drawCorridorLine(i, undefined, `${cl.id}`, '#' + (Math.random().toString(16) + "000000").substring(2,8));
                }
            }
        },
        /**
         * Обработчик клика по карте. Отправляет координаты в редакторы точек и линий
         * @param {string} x
         * @param {string} y
         */
        coordsSelected(x, y) {
            this.$refs.corridorLinesEditor.coordsSelected(x, y);
            this.$refs.pointEditor.coordsSelected(x, y);
        },
        setPointEditorLock(value) {
            this.$refs.drawer.removeDrawnObject('p:tmp');
            this.$refs.drawer.removeDrawnObject('cl:tmp');
            this.$refs.pointEditor.locked = value;
        },
        setCorridorLineEditorLock(value) {
            this.$refs.drawer.removeDrawnObject('p:tmp');
            this.$refs.drawer.removeDrawnObject('cl:tmp');
            this.$refs.corridorLinesEditor.locked = value;
        },
        tempCorridorLine(x0, y0, x1, y1) {
            console.table({ x0, y0, x1, y1 });
            this.$refs.drawer.drawCorridorLine('tmp', {
                startCoords: { x: x0, y: y0 },
                endCoords: { x: x1, y: y1 },
                id: 'tmp'
            }, `Несохранённая линия`);
        },
        linesubmitted(id, startCoords, endCoords) {
            if (!id.startsWith(this.floor + '.')) return alert('ID должен начинаться на ID текущего этажа ' + this.floor);
            new CorridorLine(id, startCoords, endCoords);
            this.haveUnsavedChanges = true;
            this.resetMap();
            this.setPointEditorLock(false);
            this.$refs.corridorLinesEditor.quit();
        },
        lineRemoved(id) {
            delete CorridorLine.list[id];
            this.resetMap();
            this.haveUnsavedChanges = true;
            this.$refs.corridorLinesEditor.quit();
        },

        tempPoint(x, y) {
            console.table({ x, y });
            this.$refs.drawer.drawPoint('tmp', {
                coords: { x: x, y: y },
                id: 'tmp',
                name: 'Несохранённая точка'
            }, 'Несохранённая точка');
        },
        pointsubmitted(id, name, connectsTo, coords, corridor, isEndpoint, group) {
            if (!id.startsWith(this.floor + '.')) return alert('ID должен начинаться на ID текущего этажа ' + this.floor);
            new Point(id, name, connectsTo, coords, corridor, isEndpoint, group);
            this.haveUnsavedChanges = true;
            this.resetMap();
            this.setCorridorLineEditorLock(false);
            this.$refs.pointEditor.quit();
        },
        pointRemoved(id) {
            delete Point.list[id];
            this.resetMap();
            this.haveUnsavedChanges = true;
            this.$refs.pointEditor.quit();
        },
        setFloor() {
            this.floor = this.floorUserInput.trim();
        },
        pointClicked(id) {
            this.$refs.corridorLinesEditor.quit();
            this.$refs.pointEditor.editExisting(id);
        },
        corrLineClicked(id) {
            this.$refs.pointEditor.quit();
            this.$refs.corridorLinesEditor.editExisting(id);
        }
    },
    mounted() {
        this.resetMap();
    },
    watch: {
        floor() {
            this.resetMap();
            this.$refs.drawer.floor = this.floor;
            this.$refs.pointEditor.floor = this.floor;
            this.$refs.corridorLinesEditor.floor = this.floor;
        }
    }
}
</script>

<style>

</style>